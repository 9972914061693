'use strict';

export default {
    headerCollapsed: state => state.header.collapsed,
    sidebarOpen: state => state.sidebarOpen,
    returnLink: state => state.returnLink,

    icon: state => iconName => state.icons[iconName] || false,
    requestedIcons: state => state.requestedIcons,

    haircutTooltipDoNotShow: (state) => {
        return state.haircutTooltipDoNotShow.map(item => item.id);
    },
    haircutTooltipClosed: (state) => {
        return state.haircutTooltipClosed.map(item => item.id);
    },

    commentBoxSelected: state => state.commentBoxSelected,
    commentBoxPrefill: state => state.commentBoxPrefill,

    preventModal: state => state.preventModal,

    hiddenProposals: ({ hiddenProposals }) => hiddenProposals,
    hiddenProposalIds: ({ hiddenProposals }) => hiddenProposals.map(proposal => proposal.id),

    /**
     * cacheKey property is used to cachebust images, if they don't send an updated_at property.
     *
     * This property should be refreshed each time an image is changed by the user.
     */
    cacheKey: state => state.cacheKey
};
